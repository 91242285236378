@-webkit-keyframes dropdown-menu {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
@keyframes dropdown-menu {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
.pageElement .text{
  h1{

  }
  h2{

  }
  h3{
    margin-bottom: 0px;
  }
  h4{

  }
  h5{

  }
  h6{

  }
}
hr{
  background: $accent-color;
}
html.collapsed-mobile-nav.slide-nav-open .theme-nav-style-slide{
  right: 0;
  top:0;
  -webkit-animation: dropdown-menu $transition-standard;
  animation: dropdown-menu $transition-standard;
}
.sportsPage #siteContainer, .gameShow #siteContainer, #TeamStatsDiv{
  @include displayCenter($maxWidth);
  padding-top: 0px;
  float: none;
}
#siteContainer{
  //@include displayCenter($maxWidth);
}
#home #siteContainer{
  max-width: 100%;
  width: 100%;
}
#TeamStatsDiv{
  border: 1px solid #000;
  padding: 10px;
}

#sport-header{
  @include displayCenter($maxWidth);
  padding-top: 0px;
  float: none;
  .sport-tools ul li{
    margin-right: 10px;
    border-bottom: 5px solid transparent;
    &.selected, &:hover{
      border-bottom: 5px solid $accent-color;
    }
    a{
      font:normal 16px/27px $fontOne;
    }
  }
}

.sportsPage{
  #panelTwo{
    .layoutContainer .pageElement.textBlockElement > h3{
      text-transform: uppercase;
      font:normal 18px/27px $fontOne;
    }
  }
}
#galleryList, .galleryItemList{
  padding: 0px;
}
#TeamStatsDiv,
#PlayerStatsDiv,
#TeamInstanceStatsDiv {
  .stat-category-header {
    font: bold 38px/1.25 $fontTwo;
    text-transform: initial;
  }
}
#sport-crumbs{
  border-bottom-width: 0px !important;
  display: none;
}
#panelTwo{
  padding: 0px;
  margin-bottom: 20px;
}
html.page-manager-visible #topNav{
  margin-left: auto;
}
#home .layoutContainer{
  .layoutInner{
    padding: 25px 0px;
  }
}
.layoutContainer{
  .center{
    margin: auto;
    display: block;
    h4{
      text-align: center;
      a{
        text-align: center;
      }
    }
  }
  .pageElement{
    &.heroPhotoElement{
      margin: 0px;
    }
    &.textBlockElement{
      > h3{
        background: $link-color;
        color: #fff;
        padding: 10px;
        font:bold 18px/27px $fontTwo;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
  .raiderTitle .textBlockElement{
    > h3{
      background-color: transparent;
      text-align: center;
      font:bold 3em/1.3em $fontTwo;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: $link-color;
      b{
        border-top:2px solid $accent-color;
        border-bottom:2px solid $accent-color;
        font:bold .4em/1em $fontOne;
        padding: 2px 3px;
        display: inline-block;
        letter-spacing: 0px;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    .text{
      @include displayCenter(700px);
    }
  }

  .layoutInner{
    @include displayCenter($maxWidth);
    //padding: 10px 0px;
  }
}
.edit_mode{
  .layoutContainer{
    .layoutimg{
      display: block;
    }
  }
  #siteContainer{
    @include displayCenter($maxWidth);
  }
  .titleBanner{
    display: block;
  }
  .subTitle{
    display: block;
  }
}
#home{
  #displayBodyHeader{
    display: none !important;
  }
  #panelTwo{
    padding: 0px;
    margin-bottom: 0px;
  }
}
.globalHeaderWrap{
  .layoutInner{
    @include displayCenter($maxWidth);
    .logoBrand{
      display: inline-block;
      width: 100%;
      max-width: 60%;
      float: left;
      padding: 15px 0px;
      #sn-site-logo{
        float: left;
        max-width: 10%;
        width: 100%;
      }
      h2{
        font:400 1.5em/1.5em $fontOne;
        color: $accent-color;
        text-transform: uppercase;
        max-width: 34%;
        width: 100%;
        float: left;
        padding-top: 15px;
        letter-spacing: 1.5px;
      }
      &:after{
        content:'';
        background: url($assetPath+'raiders.svg');
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: contain;
        max-width: 50%;
        width: 100%;
        height: 65px;
        display: block;
        float: left;
      }
    }
    .globalSocial{
      display: inline-block;
      width: 100%;
      max-width: 40%;
      float: left;
      padding: 30px 0px 15px 0px;
      h2{
        font:400 1.25em/1.5em $fontOne;
        text-transform: uppercase;
        float: right;
        display: inline-block;
        padding-left: 5px;
      }
      .sn-social-media-list{
        display: inline-block;
        float: right;
        clear: left;
      }
    }
  }
}
#topNav{
  .theme-search-bar{
    .site-tagline-text{
      flex: 1 1 66%;
      text-transform: uppercase;
      font:bold 1em/1.5em $fontTwo;
      height: auto;
    }
    .globalLink{
      height: auto;
      color: #fff;
      text-transform: uppercase;
      font:bold 1em/1.5em $fontTwo;
      text-decoration: none;
      transition:color 300ms;
      &:hover{
        color: $accent-color;
      }
      &:before{
        display: none;
      }
    }
  }
}
.snFooterContainer{
  .globalFooterWrap{
    background: $link-color;
    padding: 40px 0px;
    border-top: 4px solid $accent-color;
    .layoutInner{
      @include displayCenter($maxWidth);
      .layoutContainer{
        padding: 0px;
        .column{
          padding: 0px;
        }
        .pageEl{
          a,p{
            color: #fff;
            font-size: 1em;
          }
          a{
            font-weight: bold;
          }
        }
        .sn-social-media-list{
          a{
            color:$link-color;
            background-color: #fff;
          }
        }
      }
    }
  }
}
a[title="click to go to 'Site Guide'"] {
  display: none !important;
}
//
.logged_in a[title="click to go to 'Site Guide'"] {
  display: table-cell !important;
}
body #displayBodyHeader:not(.displayBodyHeader-image), body .displayBodyHeader-image{
  background-position: center center !important;
  background-size: cover !important;
  width: 100%;
  background-repeat: no-repeat !important;
  background-blend-mode:multiply;
  font:bold 600/1.3em $fontOne;
  border-bottom: 0px;
  height: auto !important;
  overflow: auto !important;
  background-size: cover;
  margin-bottom:27px;
  padding: 20px 15px 15px 15px;
  span{
    width: 100%;
    display: block;
    color: $link-color;
    font:bold 48px/54px $fontTwo;
    text-transform: uppercase;
    max-width: 100%;
    position: relative;
    text-indent: 0px;
    margin: auto;
    border-bottom: 2px solid $gold;
  }
}
#topNav{
  &:after{
    clear: both;
    content: '';
    display: block;
  }
}
#topNav .theme-sub-nav {
  border-bottom: 0px;
  border-top: 0px;
  display: none !important;
  float: left;
  width: 100%;
  .theme-nav {
    max-width: $maxWidth;
    width: 100%;
  }
}
html.has-mobile-nav body{
  padding-top: 0px;
}
html.collapsed-mobile-nav .theme-nav-style-slide{
  z-index: 2;
}
body #panelTwo .teamLogo-tiny{
  display: none;
}
[id^="widget_standings_widget_"] .sportTableLabel h4.teamName, #tab_division_standings_content .sportTableLabel h4.teamName{
  padding-left: 0px;
}
.hrElement{
  background: $link-color;
}
.pattern{
  &.tan{
    background: url($assetPath+'tan-pattern.png');
    background-position: center bottom;
    background-repeat: repeat;
  }
  &.purplep{
    background: url($assetPath+'purple-pattern.png');
    background-position: center bottom;
    background-repeat: repeat;
  }
  &.people{
    background: url($assetPath+'people-pattern.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.user_mode .boldCallout{
  .text{
    p{
      font: normal 38px/1.15em $fontOne;
      color: #fff;
      strong{
        font: 900 80px/1.15em $fontTwo;
        color: $accent-color;
      }
    }
  }
}
.newsPage{
  #panelTwo{
    @include displayCenter($maxWidth);
    float: none;
  }
  #displayBodyHeader{
    //display: none !important;
    padding-top: 0px;
    span{
      border: none !important;
    }
  }
  .newsItemColumn{
    max-width: 66.6%;
    .newsItemElement{
      .titleText{
        font:bold 48px/1.5em $fontTwo;
      }
      .authorDetails{
        text-transform: uppercase;
        font-size: 18px;
        .newsStats, .newsDate, .newsAuthor{
          text-transform: uppercase;
          font-size: 18px;
        }
        .newsDate{

        }
        .newsAuthor{

        }
      }
      .teaserText{
        font:bold 18px/1.5em $fontTwo;
        text-transform: none;
      }
      .previewText{

      }
    }
  }
  .bottomOptions{
    margin-bottom: 20px;
  }
}
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  //
  @return $string;
}
@mixin backgroundPath($path, $frontReplace, $backReplace, $format){
  $thisClassName: str-replace('#{&}', $frontReplace, $replace: '');
  $thisClassName: str-replace($thisClassName, $backReplace, $replace: '');
  background-image: url($path+$thisClassName+$format);
}
@mixin iconPath(){
  content: '';
  @include backgroundPath($assetPath+'icons/purple/icon-', '.ctalink.', ' a:before', '.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: inline-block;
  width: 3vw;
  height: 3vw;
  position: relative;
  left: 0px;
  margin-right: 10px;
  top:10px;
}
.ctalink{
  .linkElement h4 a{
    font: 600 1.8vw/1.5em $fontTwo;
    letter-spacing: .5px;
    color: $link-color;
    border-bottom: 4px solid $link-color;
    border-top: 2px solid $lightGrey;
    border-left: 2px solid $lightGrey;
    border-right: 2px solid $lightGrey;
    padding: 15px 15px 25px 15px;
    transition:border-bottom 300ms;
    display: block;
    &:before{
      top:10px;
      transition:top 300ms;
    }
    &:hover{
      border-bottom: 4px solid $accent-color;
/*
      &:before{
        top:7px;
      }
*/
    }
    &:after{
      display: none;
    }
  }
  b{
    font:normal 1.6vw/1.5em $fontOne;
    color: $accent-color;
    letter-spacing: 0px;
  }
  &.calendar{
    a:before{
      @include iconPath();
    }
  }
  &.stadium a{
    &:before{
      @include iconPath();
    }
  }
  &.checklist a{
    &:before{
      @include iconPath();
    }
  }
  &.hand a{
    &:before{
      @include iconPath();
    }
  }
  &.hoodie a{
    &:before{
      @include iconPath();
    }
  }
  &.map a{
    &:before{
      @include iconPath();
    }
  }
}
.cta, .cta2{
  .sn-call-to-action{
    &:before, &:after{
      display: none;
    }
    &:hover{
      h4{
        &:before, &:after{
          content: '';
          width: 80%;
          background-color: $accent-color;
        }
      }
    }
    h4{
      font: normal 36px/1em $fontOne;
      color: $accent-color;
      margin-bottom: 0px;
      &:before, &:after{
        content: '';
        height: 3px;
        display: block;
        width: 70%;
        background-color: #fff;
        transition:
          width 300ms,
          background-color 300ms;
        margin: auto;
      }
      &:before{
        margin-bottom: 3px;
      }
      &:after{
        margin-top: 3px;
      }
      b{
        font: 900 72px/1.1em $fontTwo;
        display: block;
        color: #fff;
      }
    }
  }
}
.cta2{
  .sn-call-to-action{
    &:hover{
      h4{
        &:before, &:after{
          content: '';
          //background-color: #fff;
        }
      }
    }
    h4{
      font: 900 46px/1em $fontTwo;
      color: #fff;
    }
  }
}
//
#athleticBanner{
  #displayBodyHeader:not(.displayBodyHeader-image), .displayBodyHeader-image{
    background-image: url($assetPath+'purple-pattern.png') !important;
    background-repeat: repeat !important;
    padding: 0px !important;
    background-position: center top !important;
    background-size: auto !important;
    position: relative;
    span{
      text-align: center;
      background-repeat: no-repeat;
      background-position: center top;
      border-bottom: 0px;
      max-width: calc($maxWidth - 30px);
      font:normal 80px/1em $fontOne;
      color: $accent-color;
      padding: 25px;
      &:before, &:after{
        height: 3px;
        display: block;
        margin: auto;
        position: relative;
        width: 60%;
        content: '';
        background-color: #fff;
      }
      b{
        font:900 120px/1em $fontTwo;
        color: #fff;
      }
/*
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      position: absolute;
*/
    }
  }
}
.slide-nav-open{
  .theme-slide-nav-toggle:before{
    color: $accent-color;
  }
}
.mobileBottom{
  display: none;
  position: absolute;
  bottom: 72px;
  width: 100%;
  text-align: center;
  margin: auto;
  background: #fff;
  .globalLink{
    height: auto;
    color: $link-color;
    text-transform: uppercase;
    font:normal 1em/1.5em $fontOne;
    text-decoration: none;
    transition:color 300ms;
    display: block;
    width: 100%;
    padding: 5px 0px;
    &:hover{
      color: $accent-color;
    }
    &:before{
      display: none;
    }
  }
  .sn-social-media-list{
    padding: 20px 0px 20px 0px;
  }
}
#GameShowContent ul.game_details li h3{
  font: 600 1.5em/1.4em $fontTwo;
}
.purpleOverlay{
  background: $link-color;
  display: block;
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 3;
  right: 0;
  // Fix for https://sportngin.my.salesforce.com/50014000016y7m5
  top: 0;
}
@keyframes hideEl {
    from {display: block;}
    to {display: none;}
}
@media only screen and (min-width: $maxWidth) {
  .ctalink{
    .linkElement h4 a{
      font: 600 24px/1.5em $fontTwo;
      &:before{
        width: 40px;
        height: 40px;
      }
    }
    b{
      font:normal 18px/1.5em $fontOne;
    }
  }
}
@media only screen and (max-width: $maxWidth) {
  .globalHeaderWrap{
    .layoutInner{
      .logoBrand{
        h2{
          //
          max-width: 40%;
        }
      }
    }
  }
  #topNav{
    .theme-search-bar{
      .globalLink:last-child{
        padding-right: 15px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .column, .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .sn-social-media-list, .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .column-1 .pageEl, .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .column-1 .pageEl .linkElement{
    width: 100%;
    text-align: center;
    float: none;
    display: block;
    margin: auto;
  }
  .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .column-1 .pageEl .linkElement{
    padding: 10px 0px;
    border-right: 0px !important;
  }
  .snFooterContainer .globalFooterWrap .layoutInner .layoutContainer .column{
    padding-bottom: 30px;
  }
  #sn-site-logo{
    display: block !important;
    width: 43px;
    height: 43px;
    padding: 5px;
    background: transparent;
    margin-bottom: 0px;
    a{
      span{
        //background-image: url($assetPath+'glogow.svg') !important;
      }
    }
  }
  #topNav{
    padding: 0px;
  }
  html.page-manager-visible #topNav{
    margin-left: auto;
    padding: 0px;
    #sn-site-logo{
      //left: 60px;
    }
  }
  html.collapsed-mobile-nav #topNav{
    position: relative;
    background: $link-color;
  }
  .theme-nav-title{
    &:before{
      content: '';
      display: table-cell;
      width: 45px;
      height: 45px;
    }
  }
  .dropdown-wrapper #megaDropDown-season{
    //border-top: 0px;
  }
  #sport-header{
    .sport-tools ul li{
      margin-right: 0px;
      border-left: none;
      &:first-child{
        border-left:1px solid #000;
      }
    }
  }
  .layoutContainer{
    .pageElement{
      &.textBlockElement{
        > h3{

        }
      }
    }
  }
  .theme-sub-nav{
    display: block !important;
  }
  .globalHeaderWrap{
    .layoutInner{
      .logoBrand{
        padding: 0px;
        max-width: 100%;
        #sn-site-logo{
          width: auto;
          max-width: 43px;
        }
        h2{
          display: none;
        }
        &:after{
          display: none !important;
        }
      }
      .globalSocial{
        display: none !important;
      }
    }
  }
  #topNav .theme-search-wrapper{
    display: none;
  }
  .layoutContainer{
    .raiderTitle .textBlockElement{
     > h3{
       font-size: 2em;
     }
    }
  }
  .ctalink{
    .linkElement h4 a{
      letter-spacing: 0px;
    }
  }
}
@media only screen and (max-width: 768px) {
  body #displayBodyHeader, body #displayBodyHeader:not(.displayBodyHeader-image){
    span{
      font:bold 1em/1.3em $fontTwo;
    }
  }
  .newsPage{
    #panelTwo{
      @include displayCenter($maxWidth);
      float: none;
      .newsItemElement{
        .titleText{
          font:bold 1.5em/1.5em $fontTwo;
        }
      }
    }
    #displayBodyHeader{
      //display: none !important;
      padding-top: 0px;
      span{
        border: none !important;
      }
    }
    .newsItemColumn{
      max-width: 100%;
    }
  }
  #athleticBanner{
    #displayBodyHeader:not(.displayBodyHeader-image), .displayBodyHeader-image{
      span{
        font:normal 40px/1em $fontOne;
        b{
          font:900 60px/1em $fontTwo;
          display: block;
        }
      }
    }
  }
  #sport-header{
    .sport-tools ul li{
      a{
      font-size: 0em !important;
      line-height:.5em !important;
      }
    }
  }
  .ctalink{
    .linkElement h4 a{
      font: 600 24px/1.5em $fontTwo;
      &:before{
        width: 40px;
        height: 40px;
      }
    }
    b{
      font:normal 18px/1.5em $fontOne;
    }
  }
}
.sn-social-media-list .icon-facebook:before {
  content: '\f16d' !important;
}
