#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 0px 0px 0px 0px;
    width: 100%;
    //max-width: $maxWidth;
    //min-height: 100%;
    min-height: 100vh;
    //min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    font-size: 2.25em;
    font-weight: 600;
    text-transform: initial;
/*
    float: left;
    clear: both;
*/
    display: block;
    width: 100%;
    max-width: $maxWidth;
    margin: auto;
    span{
      //@include displayCenter(1170px);
    }
  }

  #yieldContent.row { margin: auto; }
  #yieldContent {
    width: 100%;
    //padding: 10px;
  }
  .rapidContentGeneral{
    overflow: scroll;
  }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}

